@font-face {
  font-family: "D-DIN-PRO";
  src: local("D-DIN-PRO"),
    url("../src/assets/fonts/D-DIN-PRO-Regular.woff") format("woff");
  font-weight: bold;
}

@font-face {
  font-family: "D-DIN-PRO-Bold";
  src: local("D-DIN-PRO-Bold"),
    url("../src/assets/fonts/D-DIN-PRO-Bold.woff") format("woff");
  font-weight: bold;
}
@font-face {
  font-family: "D-DIN-PRO-Medium";
  src: local("D-DIN-PRO-Medium"),
    url("../src/assets/fonts/D-DIN-PRO-Medium.woff") format("woff");
  font-weight: bold;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "D-DIN-PRO";
  font-style: normal;
  font-weight: 400;
}
.label-big{
  font-weight: bold;
  text-align: center;
  opacity: 0.8;
  position: absolute;
  top: 2.4rem;
  left: -0.3rem;
  z-index: 1;
}
.label-small{
  font-weight: bold;
  text-align: center;
  opacity: 0.8;
  position: absolute;
  top: 2.4rem;
  left: -0.2rem;
  z-index: 1;
}